body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Langdon;
  src: url('./fonts/Langdon-52ov.otf') format('opentype');
}

@font-face {
  font-family: EngeHolzschrift;
  src: url('./fonts/EngeHolzschrift-PKW2.ttf') format('truetype');
}

@font-face {
  font-family: AllStarBv;
  src: url('./fonts/AllStarBv-P8P.ttf') format('truetype');
}

@font-face {
  font-family: BebasNeue;
  src: url('fonts/BebasNeue-Regular.ttf') format('truetype');
}

@font-face {
  font-family: BebasNeue;
  src: url('fonts/BebasNeue-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: BebasNeue;
  src: url('fonts/BebasNeue-Book.ttf') format('truetype');
  font-weight: book;
}

@font-face {
  font-family: BebasNeue;
  src: url('fonts/BebasNeue-Light.ttf') format('truetype');
  font-weight: light;
}

@font-face {
  font-family: BebasNeue;
  src: url('fonts/BebasNeue-Thin.ttf') format('truetype');
  font-weight: thin;
}